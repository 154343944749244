import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, AnimatedWords } from './ui';
import BlogCard from './BlogCard';
import Pagination from './Pagination';
import { BodyLarge, H1 } from './typography/Typography';

const StyledBlogListing = styled.section``;

const StyledHeader = styled.header`
  ${sectionMargins('60px', '160px')};

  max-width: 460px;
`;

const StyledHeading = styled(H1)`
  margin-bottom: 40px;

  ${minBreakpointQuery.small`
          margin-bottom: 50px;
        `}

  ${minBreakpointQuery.large`
          margin-bottom: 60px;
        `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 60px 40px;
  ${sectionMargins(undefined, '200px')};

  ${minBreakpointQuery.tiny`
    row-gap: 80px;
  `}

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.small`
    row-gap: 100px;
  `}

  ${minBreakpointQuery.medium`
    row-gap: 120px;
    padding-left: 20px;
  `}

  ${minBreakpointQuery.large`
    row-gap: 140px;
    padding-left: 40px;
  `}

  ${minBreakpointQuery.xlarge`
    padding-left: 60px;
  `}

  ${minBreakpointQuery.xxlarge`
    row-gap: 160px;
    padding-left: 80px;
  `}

  ${minBreakpointQuery.xxxlarge`
    padding-left: 100px;
  `}
`;

const StyledItem = styled(Parallax)`
  ${maxBreakpointQuery.tsmall`
    width: 90%;
  `}

  &:nth-of-type(1),
  &:nth-of-type(5),
  &:nth-of-type(6),
  &:nth-of-type(10) {
    max-width: 450px;
  }

  &:nth-of-type(2),
  &:nth-of-type(7) {
    justify-self: end;
    max-width: 350px;
  }

  &:nth-of-type(3),
  &:nth-of-type(8) {
    justify-self: center;
    max-width: 640px;

    ${minBreakpointQuery.tsmall`
      grid-column: span 2;
    `}
  }

  &:nth-of-type(4),
  &:nth-of-type(9) {
    max-width: 540px;

    ${minBreakpointQuery.tsmall`
      justify-self: end;
    `}
  }

  &:nth-of-type(5),
  &:nth-of-type(10) {
    ${maxBreakpointQuery.tsmall`
      justify-self: end;
    `}
  }
`;

const StyledFooter = styled.footer`
  ${sectionMargins('60px', '120px')};
`;

const BlogListing = ({ heading, items, meta, introduction }) => {
  return (
    <StyledBlogListing>
      <Container>
        <StyledHeader>
          <StyledHeading currentPage={meta.currentPage}>
            <AnimatedWords>{heading}</AnimatedWords>
          </StyledHeading>
          <BodyLarge>{introduction}</BodyLarge>
        </StyledHeader>
        {items.length > 0 && (
          <StyledItems>
            {items.map((item, i) => (
              <StyledItem key={i} speed={i % 2 ? 4 : -4}>
                <BlogCard
                  image={
                    i === 2 || i === 3 || i === 7 || i === 8
                      ? item.landscapeImage
                      : item.portraitImage
                  }
                  {...item}
                />
              </StyledItem>
            ))}
          </StyledItems>
        )}
        <StyledFooter>
          <Pagination {...meta} />
        </StyledFooter>
      </Container>
    </StyledBlogListing>
  );
};

export default BlogListing;
