import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, fontSize } from '../styles';
import { Link } from './ui';

const StyledPagination = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;

  ${minBreakpointQuery.small`
    gap: 80px;
  `}

  ${minBreakpointQuery.large`
    gap: 100px;
  `}
`;

const StyledItems = styled.ol`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const StyledItem = styled.li``;

const StyledLink = styled(Link)`
  padding: 5px;

  ${minBreakpointQuery.large`
    ${fontSize(18)};
  `}

  &:disabled {
    opacity: 0.5;
    cursor: inherit;
  }

  &:not([disabled]):hover,
  &.current-page {
    text-decoration: underline;
  }
`;

const Pagination = ({ baseUrl, currentPage, totalPages }) =>
  totalPages > 1 && (
    <StyledPagination>
      <StyledLink
        {...(currentPage !== 1
          ? {
              to: `${baseUrl}${
                currentPage !== 2 ? `page/${currentPage - 1}` : ''
              }`,
            }
          : {
              disabled: true,
            })}
      >
        Previous
      </StyledLink>
      <StyledItems>
        {[...Array(totalPages)].map((item, i) => {
          const pageCount = i + 1;

          return (
            <StyledItem>
              <StyledLink
                to={`${baseUrl}${pageCount > 1 ? `page/${pageCount}` : ''}`}
                activeClassName="current-page"
              >
                {pageCount}
              </StyledLink>
            </StyledItem>
          );
        })}
      </StyledItems>
      <StyledLink
        {...(currentPage !== totalPages
          ? {
              to: `${baseUrl}${`page/${currentPage + 1}`}`,
            }
          : {
              disabled: true,
            })}
      >
        Next
      </StyledLink>
    </StyledPagination>
  );

export default Pagination;
