import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { minBreakpointQuery, standardTransition, delays } from '../styles';
import { Link } from './ui';
import { BodyLarge, H5 } from './typography/Typography';

// moved up for hover
const StyledImage = styled(GatsbyImage)`
  transition: ${standardTransition('transform')};
`;

const StyledBlogCard = styled.article`
  cursor: pointer;
  animation: ${standardTransition('fadeUp', undefined, undefined, delays.long)}
    both;

  &:hover {
    ${StyledImage} {
      transform: scale(1.15);
    }
  }
`;

const StyledImageWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
`;

const StyledContent = styled.div`
  margin-top: 24px;
  max-width: 340px;

  ${minBreakpointQuery.small`
    margin-top: 28px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 32px;
  `}
`;

const StyledHeading = styled(H5)`
  text-transform: uppercase;
`;

const StyledText = styled(BodyLarge)`
  margin-top: 16px;
`;

const BlogCard = ({ image, heading, text, slug, ...props }) => {
  const urlPath = `/blog/${slug}`;

  return (
    <Link href={urlPath}>
      <StyledBlogCard {...props}>
        <StyledImageWrapper>
          <StyledImage image={image.gatsbyImageData} alt={image.alt} />
        </StyledImageWrapper>
        <StyledContent>
          <StyledHeading>{heading}</StyledHeading>
          {text && <StyledText>{text}</StyledText>}
        </StyledContent>
      </StyledBlogCard>
    </Link>
  );
};

export default BlogCard;

export const BlogCardFragment = graphql`
  fragment BlogCardFragment on DatoCmsBlog {
    landscapeImage: cardImage {
      gatsbyImageData(width: 640, height: 480)
      alt
    }
    portraitImage: cardImage {
      gatsbyImageData(width: 480, height: 640)
      alt
    }
    heading: title
    text: cardText
    slug
    locale
    __typename
  }
`;
