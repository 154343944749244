import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import BlogListing from '../components/BlogListing';

const BlogCategoryTemplate = ({
  data: {
    datoCmsBlogCategory: { seoMetaTags, title, introduction },
    allDatoCmsBlog: { nodes },
  },
  pageContext,
}) => (
  <Layout seo={seoMetaTags}>
    <main>
      <BlogListing
        heading={title}
        items={nodes}
        meta={pageContext}
        introduction={introduction}
      />
    </main>
  </Layout>
);

export const BlogCategoryTemplateQuery = graphql`
  query BlogCategoryTemplateQuery($id: String!, $limit: Int!, $skip: Int!) {
    datoCmsBlogCategory(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      introduction
    }
    allDatoCmsBlog(
      filter: {
        categories: { elemMatch: { id: { eq: $id } } }
        locale: { eq: "en" }
      }
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...BlogCardFragment
      }
    }
  }
`;

export default BlogCategoryTemplate;
